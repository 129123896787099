global.validate_user_name = 'Por favor ingrese el nombre'
window.validate_user_name = 'Por favor ingrese el nombre'
global.validate_user_email = 'Por favor ingrese su correo electrónico'
window.validate_user_email = 'Por favor ingrese su correo electrónico'
global.validate_user_phone_number = 'Por favor número de teléfono'
window.validate_user_phone_number = 'Por favor número de teléfono'
global.validate_user_license_number = 'Por favor número de licencia'
window.validate_user_license_number = 'Por favor número de licencia'
global.validate_user_password = 'Por favor contraseña'
window.validate_user_password = 'Por favor contraseña'
global.validate_new_user_password = 'Por favor ingrese nueva contraseña'
window.validate_new_user_password = 'Por favor ingrese nueva contraseña'
global.validate_confirm_user_password = 'Por favor, confirme su contraseña'
window.validate_confirm_user_password = 'Por favor, confirme su contraseña'
global.validate_current_user_password = 'Ingrese la contraseña actual'
window.validate_current_user_password = 'Ingrese la contraseña actual'
global.validate_user_terms_conditions = 'Por seleccione Términos y condiciones'
window.validate_user_terms_conditions = 'Por seleccione Términos y condiciones'
window.locale_property_details = 'Must-Haves'
global.locale_property_details = 'Must-Haves'
window.locale_add_listing_property_details = '¿Agua y/o Luz incluído?'
global.locale_add_listing_property_details = '¿Agua y/o Luz incluído?'
global.validate_lead_full_name = 'Por favor ingrese el nombre'
window.validate_lead_full_name = 'Por favor ingrese el nombre'
global.validate_lead_email = 'Por favor ingrese su correo electrónico'
window.validate_lead_email = 'Por favor ingrese su correo electrónico'
global.validate_lead_phone_number = 'Por favor número de teléfono'
window.validate_lead_phone_number = 'Por favor número de teléfono'
global.search_page_counts_global = "Propiedades"
window.search_page_counts_global = "Propiedades"
global.search_page_count_global = "Property"
window.search_page_count_global = "Propiedad"
global.warning_to_select_compare_button_length_global_ = "Puede seleccionar solo tres agentes para comparar"
window.warning_to_select_compare_button_length_global = "Puede seleccionar solo tres agentes para comparar"
global.validate_lead_contact_number = 'Por favor ingrese el número de contacto'
window.validate_lead_contact_number = 'Por favor ingrese el número de contacto'
global.validate_user_company_address = 'Por favor ingrese la direccion'
window.validate_user_company_address = 'Por favor ingrese la direccion'
global.validate_user_address_and_city = 'Ingrese ciudad, lugar o código postal'
window.validate_user_address_and_city = 'Ingrese ciudad, lugar o código postal'
global.validate_text_only = "Ingrese caracteres alfabéticos"
window.validate_text_only = "Ingrese caracteres alfabéticos"
global.validate_name = 'Ingrese un nombre válido'
window.validate_name = 'Ingrese un nombre válido'
global.already_exist_email = 'El correo electrónico ya sale'
window.already_exist_email = 'El correo electrónico ya sale'
global.validate_email = 'Por favor introduzca un correo electrónico válido'
window.validate_email = 'Por favor introduzca un correo electrónico válido'
global.license_number_minlength = 'El número de Liscence debe tener 6 caracteres'
window.license_number_minlength = 'El número de Liscence debe tener 6 caracteres'
global.license_number_maxlength = 'El número de Liscence no debe exceder los 20 caracteres'
window.license_number_maxlength = 'El número de Liscence no debe exceder los 20 caracteres'
global.validate_user_type = 'Seleccione el tipo de usuario'
window.validate_user_type = 'Seleccione el tipo de usuario'
global.validate_existing_email = "Ingrese el correo electrónico existente"
window.validate_existing_email = "Ingrese el correo electrónico existente"
global.password_minlength = 'La contraseña debe tener 6 o más caracteres'
window.password_minlength = 'La contraseña debe tener 6 o más caracteres'
global.password_maxlength = 'La contraseña no debe superar los 20 caracteres.'
window.password_maxlength = 'La contraseña no debe superar los 20 caracteres.'
global.confirm_password_required = 'Por favor, confirme su contraseña'
window.confirm_password_required = 'Por favor, confirme su contraseña'
global.password_does_not_match = 'La nueva contraseña y la confirmación de la nueva contraseña no coinciden'
window.password_does_not_match = 'La nueva contraseña y la confirmación de la nueva contraseña no coinciden'
global.validate_current_password = 'La contraseña actual no es correcta'
window.validate_current_password = 'La contraseña actual no es correcta'
global.confirm_new_password_not_match = 'La nueva contraseña y la confirmación de la nueva contraseña no coinciden'
window.confirm_new_password_not_match =  'La nueva contraseña y la confirmación de la nueva contraseña no coinciden'
global.validate_name_minlength = 'El nombre debe tener más de 2 caracteres'
window.validate_name_minlength = 'El nombre debe tener más de 2 caracteres'
global.validate_name_maxlength = 'El nombre no debe exceder los 100 caracteres'
window.validate_name_maxlength = 'El nombre no debe exceder los 100 caracteres'
global.phone_minlength = 'El número de teléfono debe tener 10 dígitos'
window.phone_minlength = 'El número de teléfono debe tener 10 dígitos'
global.phone_maxlength = 'El teléfono no debe superar los 10 caracteres'
window.phone_maxlength = 'El teléfono no debe superar los 10 caracteres'
global.email_minlength = 'El correo electrónico debe tener más de 2 caracteres'
window.email_minlength = 'El correo electrónico debe tener más de 2 caracteres'
global.validate_email_maxlength = 'El correo electrónico no debe exceder los 50 caracteres'
window.validate_email_maxlength = 'El correo electrónico no debe exceder los 50 caracteres'
global.validate_bio_minlength = 'La biografía debe tener más de 5 caracteres'
window.validate_bio_minlength = 'La biografía debe tener más de 5 caracteres'
global.validate_bio_maxlength = 'La biografía no debe superar los 500 caracteres'
window.validate_bio_maxlength = 'La biografía no debe superar los 500 caracteres'
global.validate_website_link = 'Introduzca una dirección URL válida (por ejemplo: www.website.com).'
window.validate_website_link = 'Introduzca una dirección URL válida (por ejemplo: www.website.com).'
global.validate_facebook_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://facebook.com/'
window.validate_facebook_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://facebook.com/'
global.validate_insta_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://instagram.com/'
window.validate_insta_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://instagram.com/'
global.validate_linkedin_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://linkedin.com/'
window.validate_linkedin_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://linkedin.com/'
global.validate_youtube_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://youtube.com/'
window.validate_youtube_link = 'Introduzca un formato válido que incluya https: // o http: //. Puede copiar / pegar directamente el enlace. Por ejemplo, https://youtube.com/'
global.validate_image_file = 'Cargue solo archivos jpg, jpeg, png'
window.validate_image_file = 'Cargue solo archivos jpg, jpeg, png'
global.validate_contact_minlength = 'El número de contacto debe tener 10 dígitos'
window.validate_contact_minlength = 'El número de contacto debe tener 10 dígitos'
global.validate_contact = 'Ingrese un número de contacto válido'
window.validate_contact = 'Ingrese un número de contacto válido'
global.validate_messages_minlength = 'Los mensajes deben tener más de 5 caracteres'
window.validate_messages_minlength = 'Los mensajes deben tener más de 5 caracteres'
global.validate_messages_maxlength = 'Los mensajes no deben exceder los 1000 caracteres'
window.validate_messages_maxlength = 'Los mensajes no deben exceder los 1000 caracteres'
global.validate_captcha = 'Por favor, verifica que eres humano!'
window.validate_captcha = 'Por favor, verifica que eres humano!'
global.required_message = 'Por favor ingrese el mensaje'
window.required_message = 'Por favor ingrese el mensaje'
global.validate_card = 'Ingrese un número de tarjeta válido'
window.validate_card = 'Ingrese un número de tarjeta válido'
global.validate_cvv = "Ingrese un CVV válido"
window.validate_cvv = "Ingrese un CVV válido"
global.required_list_type = 'Seleccione el tipo de lista'
window.required_list_type = 'Seleccione el tipo de lista'
global.required_property_type = 'Seleccione el tipo de propiedad'
window.required_property_type = 'Seleccione el tipo de propiedad'
global.validate_property_name = 'Ingrese el nombre de la propiedad'
window.validate_property_name = 'Ingrese el nombre de la propiedad'
global.property_name_minlength = "El nombre de la propiedad debe tener más de 2 caracteres"
window.property_name_minlength = "El nombre de la propiedad debe tener más de 2 caracteres"
global.property_name_maxlength = "El nombre de la propiedad debe tener menos de 100 caracteres"
window.property_name_maxlength = "El nombre de la propiedad debe tener menos de 100 caracteres"
global.validate_property_name = "Ingrese un nombre de propiedad válido"
window.validate_property_name = "Ingrese un nombre de propiedad válido"
global.validate_required_city = 'Por favor ingrese la ciudad'
window.validate_required_city = 'Por favor ingrese la ciudad'
global.validate_city_minlength = "La ciudad debe tener más de 2 caracteres"
window.validate_city_minlength = "La ciudad debe tener más de 2 caracteres"
global.validate_city_maxlength = "La ciudad debe tener menos de 100 caracteres"
window.validate_city_maxlength = "La ciudad debe tener menos de 100 caracteres"
global.validate_city = "Ingrese una ciudad válida"
window.validate_city = "Ingrese una ciudad válida"
global.validate_zipcode = "Ingrese un código postal válido"
window.validate_zipcode = "Ingrese un código postal válido"
global.validate_zipcode_minlength = 'El código postal debe tener 2 caracteres'
window.validate_zipcode_minlength = 'El código postal debe tener 2 caracteres'
global.validate_zipcode_maxlength = 'El código postal debe tener 6 caracteres iguales'
window.validate_zipcode_maxlength = 'El código postal debe tener 6 caracteres iguales'
global.validate_location = 'Por favor ingrese la ubicación'
window.validate_location = 'Por favor ingrese la ubicación'
global.location_minlength = "La ubicación debe tener más de 1 carácter"
window.location_minlength = "La ubicación debe tener más de 1 carácter"
global.location_maxlength = "La ubicación debe tener menos de 300 caracteres"
window.location_maxlength = "La ubicación debe tener menos de 300 caracteres"
global.validate_trim_location = "Ingrese una ubicación válida"
window.validate_trim_location = "Ingrese una ubicación válida"
global.validate_required_price = 'Por favor ingrese el precio'
window.validate_required_price = 'Por favor ingrese el precio'
global.validate_price = "El precio debe estar en dos decimales"
window.validate_price = "El precio debe estar en dos decimales"
global.validate_required_bedroom = 'Por favor seleccione dormitorio'
window.validate_required_bedroom = 'Por favor seleccione dormitorio'
global.validate_required_bathroom = 'Por favor seleccione baño'
window.validate_required_bathroom = 'Por favor seleccione baño'
global.validate_required_utilities = 'Seleccione utilidades'
window.validate_required_utilities = 'Seleccione utilidades'
global.validate_required_property_details = 'Seleccione los detalles de la propiedad'
window.validate_required_property_details = 'Seleccione los detalles de la propiedad'
global.required_property_description = "Ingrese una descripción válida"
window.required_property_description = "Ingrese una descripción válida"
global.property_description_minlength = "La descripción de la propiedad debe tener más de 2 caracteres"
window.property_description_minlength = "La descripción de la propiedad debe tener más de 2 caracteres"
global.property_description_maxlength = "La descripción de la propiedad debe tener menos de 1300 caracteres"
window.property_description_maxlength = "La descripción de la propiedad debe tener menos de 1300 caracteres"
global.required_3d_link = 'Ingrese un enlace 3d válido'
window.required_3d_link = 'Ingrese un enlace 3d válido'
global.required_tour_link = 'Ingrese un enlace de tour válido'
window.required_tour_link = 'Ingrese un enlace de tour válido'
global.validate_full_name = "Por favor ingrese su nombre completo"
window.validate_full_name = "Por favor ingrese su nombre completo"
global.requierd_expiry_month = "Ingrese el mes de vencimiento"
window.requierd_expiry_month = "Ingrese el mes de vencimiento"
global.requierd_expiry_year = "Ingrese el año de vencimiento"
window.requierd_expiry_year = "Ingrese el año de vencimiento"
global.required_cvv = "Por favor ingrese cvv"
window.required_cvv = "Por favor ingrese cvv"
global.validate_cvv_minlength = "CVV debe tener un mínimo de 3 caracteres"
window.validate_cvv_minlength = "CVV debe tener un mínimo de 3 caracteres"
global.validate_cvv_maxlength = "CVV debe tener un máximo de 3 caracteres"
window.validate_cvv_maxlength = "CVV debe tener un máximo de 3 caracteres"
global.required_billing_name = "Ingrese el nombre de facturación"
window.required_billing_name = "Ingrese el nombre de facturación"
global.required_billing_address = "Ingrese la dirección de facturación"
window.required_billing_address = "Ingrese la dirección de facturación"
global.required_zipcode = "Por favor ingrese el código postal"
window.required_zipcode = "Por favor ingrese el código postal"
global.validate_zipcode_max = "El código postal debe tener un máximo de 7 caracteres"
window.validate_zipcode_max = "El código postal debe tener un máximo de 7 caracteres"
global.validate_required_country = "Por favor ingrese el pais"
window.validate_required_country = "Por favor ingrese el pais"
global.validate_property_size_id_global = "Ingrese el tamaño de la propiedad"
window.validate_property_size_id_global = "Ingrese el tamaño de la propiedad"
global.validate_property_size_global = "Ingrese la medida del tamaño de la propiedad"
window.validate_property_size_global = "Ingrese la medida del tamaño de la propiedad"
global.validate_gallery_dimension = 'Seleccione una imagen con más de 800X600 px (ancho X alto) '
window.validate_gallery_dimension = 'Seleccione una imagen con más de 800X600 px (ancho X alto) '
global.validate_gallery_image_size = 'Seleccione un tamaño de imagen menor o igual a 5 MB'
window.validate_gallery_image_size = 'Seleccione un tamaño de imagen menor o igual a 5 MB'
global.validate_gallery_image_number = 'Seleccione un máximo de 4 imágenes'
window.validate_gallery_image_number = 'Seleccione un máximo de 4 imágenes'
global.validate_shedule_date = "Seleccione la fecha del programa"
window.validate_shedule_date =  "Seleccione la fecha del programa"
global.validate_start_time = "Seleccione la hora de inicio"
window.validate_start_time = "Seleccione la hora de inicio"
global.validate_end_date = "Seleccione la hora de finalización"
window.validate_end_date = "Seleccione la hora de finalización"
global.delete_message_to_gallery_image = '¿Vas a eliminar esta foto? '
window.delete_message_to_gallery_image = '¿Vas a eliminar esta foto? '
global.flash_message_delete_global_success = '¡Éxito!'
window.flash_message_delete_global_success = '¡Éxito!'
global.flash_message_delete_global_alert = '¡Alerta!'
window.flash_message_delete_global_alert = '¡Alerta!'
global.validate_gallery_image_number = "Solo se han subido 10 imágenes válidas "
window.validate_gallery_image_number = "Solo se han subido 10 imágenes válidas "
global.validate_gallery_image_dimension_size = "Solo se ha subido una imagen válida de dimensión superior a 1600X600 px (ancho X alto) y tamaño inferior a 5 MB."
window.validate_gallery_image_dimension_size = "Solo se ha subido una imagen válida de dimensión superior a 1600X600 px (ancho X alto) y tamaño inferior a 5 MB."
global.validate_gallery_image_number_size = "Solo se han subido 10 imágenes válidas de tamaño inferior a 5 MB"
window.validate_gallery_image_number_size = "Solo se han subido 10 imágenes válidas de tamaño inferior a 5 MB"
global.validate_gallery_image_number_dimension = "Solo se han subido 10 imágenes válidas de dimensión superior a 1600X600 px (ancho X alto)"
window.validate_gallery_image_number_dimension = "Solo se han subido 10 imágenes válidas de dimensión superior a 1600X600 px (ancho X alto)"
global.validate_gallery_image_number_dimension_size = "Solo se han subido 10 imágenes válidas de dimensión más grande que 1600X600 px (ancho X alto) y menos de 5 MB."
window.validate_gallery_image_number_dimension_size = "Solo se han subido 10 imágenes válidas de dimensión más grande que 1600X600 px (ancho X alto) y menos de 5 MB."
global.add_plan_selected_text = 'Seleccionado'
global.add_plan_select_text = 'Seleccionado'
global.add_plan_select_text = 'Seleccione'
global.add_plan_select_text = 'Seleccione'
